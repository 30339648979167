<messages>["./DomainTransfer"]</messages>

<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model.trim="domain"
          class="required"
          spellcheck="false"
          :label="$t ('label.namedomain')"
          :error-messages="validationErrors (
            'punycodeDomainName', {
              required: 'error.required.domain',
              domain: 'error.invalidDomain'
            })"
          @blur="$v.punycodeDomainName.$touch"/>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model.trim="authInfo"
          :class="{required: isTransferRequiresAuthInfo}"
          spellcheck="false"
          :label="$t ('label.authInfo')"
          :error-messages="validationErrors (
            'authInfo', {required: 'error.required.auth'})"
          @blur="$v.authInfo.$touch"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import {toASCII as punycodeToASCII} from 'punycode/punycode.es6'
  import {idnDomain} from '@/app/validators/index'
  import validationMixins from '@/app/core/mixins/ValidationHelper'
  import {required} from 'vuelidate/lib/validators'

  import {mapGetters} from 'vuex'

  export default {
    name: 'BasicTransferData',

    mixins: [validationMixins],

    props: {
      isLoading: Boolean,
      value: {
        type: Object,
        required: true
      }
    },

    validations: {
      punycodeDomainName: {
        required,
        domain: idnDomain
      },
      authInfo: {
        required (val) {
          return !this.isTransferRequiresAuthInfo || required (val)
        }
      }
    },

    data () {
      return {
        domain: '',
        authInfo: ''
      }
    },

    computed: {
      ...mapGetters ('meta', [
        'isTransferRequiresAuthInfo'
      ]),

      punycodeDomainName () {
        return punycodeToASCII (this.domain)
      }
    },

    watch: {
      domain () {
        this.$emit ('input', {
          domainName: this.domain,
          authInfo: this.authInfo
        })
      },

      authInfo () {
        this.$emit ('input', {
          domainName: this.domain,
          authInfo: this.authInfo
        })
      }
    },

    created () {
      this.domain = this.value.domainName
      this.authInfo = this.value.authInfo
    },

    methods: {
      reset () {
        this.domain = ''
        this.authInfo = ''
        this.$v.$reset ()
      },

      /**
       * Check whether the form has any errors, i.e., contains at least one
       * field with invalid data that is flagged "dirty".
       *
       * @return {Boolean}      true if so
       */
      hasErrors () {
        return this.$v.punycodeDomainName.$error || this.$v.authInfo.$error
      },

      /**
       * Check whether the form data is valid, i.e., all fields contain valid
       * values.
       *
       * @return {Boolean}      true if so
       */
      isValid () {
        return !this.$v.punycodeDomainName.$invalid && !this.$v.authInfo.$invalid
      }
    }
  }
</script>
