<messages>["./DomainTransfer"]</messages>

<template>
  <v-stepper
    v-model="step"
    vertical>
    <!-- basic data -->
    <v-stepper-step
      step="1"
      editable
      :complete="step > 1"
      :rules="[() => isBasicDataValid ()]">
      {{ $t ('label.basicData') }}
      <small v-t="'label.basicDataSub'"/>
    </v-stepper-step>
    <v-stepper-content step="1">
      <v-card class="elevation-0">
        <form
          novalidate
          @submit.prevent>
          <v-card-text>
            <basic-transfer-data
              ref="transferBasicData"
              v-model="basicData"/>
          </v-card-text>
          <v-card-actions>
            <navigation-buttons
              :step="1"
              @next="step = 2"/>
          </v-card-actions>
        </form>
      </v-card>
    </v-stepper-content>

    <!-- additional data -->
    <v-stepper-step
      step="2"
      editable
      :complete="step > 2"
      :rules="[() => isAdditionalDataValid ()]">
      {{ $t ('label.updateData') }}
      <small v-t="'label.updateDataSub'"/>
    </v-stepper-step>
    <v-stepper-content step="2">
      <v-card class="elevation-0">
        <form
          novalidate
          @submit.prevent>
          <v-card-text>
            <transfer-update-data
              ref="transferAdditionalData"
              v-model="additionalData"
              :registry-id="value.registryId"
              :client-id="value.clientId"/>
          </v-card-text>
          <v-card-actions>
            <navigation-buttons
              last
              :step="2"
              @prev="step = 1"/>
          </v-card-actions>
        </form>
      </v-card>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
  import BasicTransferData from './BasicTransferData'
  import TransferUpdateData from './TransferUpdateData'

  import NavigationButtons
    from '@/app/core/components/RegistryObject/NavigationButtons'

  import ValidationParent from '@/app/core/mixins/ValidationParent'
  import StepValidatorMixin from '@/app/core/mixins/StepValidatorMixin'

  import {mapActions} from 'vuex'

  export default {
    name: 'Stepper',

    components: {
      BasicTransferData,
      NavigationButtons,
      TransferUpdateData
    },

    mixins: [ValidationParent, StepValidatorMixin],

    props: {
      value: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        step: 1,
        transferObject: {
          authInfo: '',
          contacts: [],
          hosts: [],
          dnssecData: [],
          providerChainSpec: '',
          providerChainType: ''
        }
      }
    },

    computed: {
      // value for StepValidatorMixin
      stepToRefMap () {
        return {
          1: this.$refs.transferBasicData,
          2: this.$refs.transferAdditionalData
        }
      },

      basicData: {
        get () {
          return {
            domainName: this.value.domainName,
            authInfo: this.transferObject.authInfo
          }
        },

        set (val) {
          this.$emit ('input', {...this.value, domainName: val.domainName})
          this.transferObject.authInfo = val.authInfo
        }
      },

      additionalData: {
        get () {
          return {
            contacts: this.transferObject.contacts,
            hosts: this.transferObject.hosts,
            dnssecData: this.transferObject.dnssecData,
            providerChainSpec: this.transferObject.providerChainSpec,
            providerChainType: this.transferObject.providerChainType,
            wapOverride: this.transferObject.wapOverride
          }
        },

        set (val) {
          this.transferObject.wapOverride = val.wapOverride
          this.transferObject.contacts = val.contacts
          this.transferObject.hosts = val.hosts
          this.transferObject.dnssecData = val.dnssecData
          this.transferObject.providerChainSpec = val.providerChainSpec
          this.transferObject.providerChainType = val.providerChainType
        }
      }

    },

    watch: {
      'transferObject.authInfo' (newVal) {
        this.$emit ('input', {...this.value, authInfo: newVal})
      },

      'transferObject.providerChainSpec' (newVal) {
        this.$emit ('input', {...this.value, providerChainSpec: newVal})
      },

      'transferObject.providerChainType' (newVal) {
        this.$emit ('input', {...this.value, providerChainType: newVal})
      },

      'transferObject.contacts': {
        handler (newVal) {
          this.$emit ('input', {...this.value, contacts: newVal})
        },
        deep: true
      },

      'transferObject.hosts': {
        handler (newVal) {
          this.$emit ('input', {...this.value, hosts: newVal})
        },
        deep: true
      },

      'transferObject.dnssecData': {
        handler (newVal) {
          this.$emit ('input', {...this.value, dnssecData: newVal})
        },
        deep: true
      }

    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      /**
       * Get the references of all sub-components for validity checks.
       *
       * @return {Array}      the reference objects
       */
      getComponentRefs () {
        return [
          this.$refs.transferBasicData,
          this.$refs.transferAdditionalData
        ]
      },

      /**
       * Check whether the basic transfer data is valid.
       *
       * @return {Boolean}      true if so
       */
      isBasicDataValid () {
        return !this.$refs.transferBasicData ||
          !this.$refs.transferBasicData.hasErrors ()
      },

      /**
       * Check whether the additional transfer data is valid.
       *
       * @return {Boolean}      true if so
       */
      isAdditionalDataValid () {
        return !this.$refs.transferAdditionalData ||
          !this.$refs.transferAdditionalData.hasErrors ()
      },

      reset () {
        this.step = 1
        this.response = null
        this.basicData = {...this.basicData, authInfo: ''}

        this.$refs.transferBasicData.reset ()
        this.$refs.transferAdditionalData.reset ()
      }
    }
  }
</script>
