<messages>["../Domain", "./DomainTransfer"]</messages>

<template>
  <div>
    <base-layout mw3>
      <v-col cols="12">
        <!-- success message -->
        <v-alert
          v-model="isSuccessAlertVisible"
          type="success"
          dismissible>
          <i18n path="success">
            <!-- Link to transfer status page -->
            <template #link>
              <router-link
                class="success-alert-link"
                :to="{name: 'transferstatus.check', query: { domain:successDomain }}"
                v-text="successDomain"/>
            </template>
          </i18n>
        </v-alert>
      </v-col>
    </base-layout>

    <base-layout mw0>
      <v-col
        v-if="showDomainInput"
        cols="12">
        <form
          novalidate
          @submit.prevent="onEnterDomainName">
          <v-card>
            <v-card-title>
              <span
                v-t="'label.requestTransfer'"
                class="text-h5"/>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model.trim="domainName"
                    autofocus
                    spellcheck="false"
                    :loading="isLoadingRegistry || !showDomainInput"
                    :error-messages="domainNameValidationErrors"
                    @input="registryLoadingFailed = false"/>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn
                v-t="'label.selectRegistry'"
                text
                @click="onManualMode"/>
              <v-btn
                v-t="'general.button.ok'"
                color="primary"
                type="submit"/>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
    </base-layout>

    <v-alert
      id="invalidDataAlert"
      type="error"
      :value="isInvalidDataAlertVisible">
      {{
        formErrors
          ? $t ('error.form')
          : $t ('error.request')
      }}
    </v-alert>

    <transfer
      v-show="!showDomainInput"
      :registry-id="registryId"
      :domain-name="domainName"
      @success="onSuccess"
      @failure="onFailure"
      @dataValidityChanged="onDataValidityChanged"
      @startOver="onStartOver"
      @cancel="back"/>
  </div>
</template>

<script>
  import {toASCII as punycodeToASCII} from 'punycode/punycode.es6'
  import goTo from 'vuetify/lib/services/goto'

  import Transfer from './Transfer'

  import BaseLayout from '@/app/core/components/BaseLayout'

  import validationMixins from '@/app/core/mixins/ValidationHelper'

  import {idnDomain} from '@/app/validators'
  import {required} from 'vuelidate/lib/validators'

  import {mapActions, mapMutations} from 'vuex'

  export default {
    name: 'DomainTransfer',

    validations: {
      punycodeDomainName: {
        required,
        domain: idnDomain
      }
    },

    components: {
      Transfer,
      BaseLayout
    },

    mixins: [validationMixins],

    data () {
      return {
        isSuccessAlertVisible: false,
        isInvalidDataAlertVisible: false,
        registryLoadingFailed: false,
        registryId: '',
        formErrors: false,
        successDomain: null,
        showDomainInput: true,
        domainName: '',
        isLoadingRegistry: false
      }
    },

    computed: {
      domainNameValidationErrors () {
        let valErr = this.validationErrors ('punycodeDomainName', {
          domain: this.$t ('general.invalid.domainName'),
          required: this.$t ('general.required')
        })

        if (valErr.length === 0 && this.registryLoadingFailed) {
          valErr = this.$t ('create.nameInput.invalidEnding')
        }

        return valErr
      },
      punycodeDomainName () {
        return punycodeToASCII (this.domainName)
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      ...mapMutations ('notification', ['addEvent']),

      onSuccess (e) {
        this.successDomain = e
        this.addEvent ({
          type: 'success',
          message: this.$t ('success', {link: this.successDomain}),
          objects: [{name: this.successDomain, link: {name: 'transferstatus.check', query: {domain: this.successDomain}}}]
        })
        this.formErrors = false
        this.isInvalidDataAlertVisible = false
        this.isSuccessAlertVisible = true
        this.domainName = ''
        this.registryId = ''
        this.showDomainInput = true
        this.$v.$reset ()
      },

      onFailure () {
        this.isSuccessAlertVisible = false
        this.formErrors = false
        this.isInvalidDataAlertVisible = true
        this.scrollToAlert ()
      },

      onDataValidityChanged (isValid) {
        this.formErrors = !isValid
        this.isInvalidDataAlertVisible = !isValid
      },

      back () {
        this.$router.back ()
      },

      onEnterDomainName () {
        this.$v.$touch ()

        if (!this.$v.$error) {
          this.isLoadingRegistry = true

          this.fetchData ({
            op: 'registry/forDomain',
            params: {
              name: this.domainName
            },
            cb: data => {
              if (data.registryId === null) {
                this.registryLoadingFailed = true
              } else {
                this.registryId = data.registryId
                this.showDomainInput = false
              }
            },
            cbFinal: () => {
              this.isLoadingRegistry = false
            }
          })
        }
      },

      onManualMode () {
        this.domainName = ''
        this.registryId = ''
        this.showDomainInput = false
      },

      onStartOver () {
        this.domainName = ''
        this.registryId = ''
        this.$v.$reset ()
        this.showDomainInput = true
      },

      scrollToAlert () {
        goTo ('#invalidDataAlert')
      }
    }
  }
</script>
