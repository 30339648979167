<messages>["./DomainTransfer"]</messages>

<template>
  <div>
    <template v-if="!isLoading && !regError">
      <v-slide-y-transition mode="out-in">
        <v-row key="content">
          <v-col
            v-if="editContactsVisible"
            cols="12">
            <v-switch
              v-model="contactEdit"
              class="ml-1"
              :label="$t ('label.editContacts')"
              :disabled="editContactsDisabled"
              :messages="editContactsDisabled ? $t ('label.updateRequired') : ''"/>
          </v-col>

          <v-expand-transition>
            <v-row
              v-show="edit.contacts"
              class="mb-1">
              <v-col cols="12">
                <v-card>
                  <v-card-text>
                    <domain-contact-data
                      ref="domainContactData"
                      v-model="contactData"
                      :extra-data="{}"
                      no-wap/>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-expand-transition>

          <v-col v-if="editNameServersVisible" cols="12">
            <v-switch
              v-model="hostEdit"
              class="ml-1"
              :label="$t ('label.editNameservers')"
              :disabled="editNameServersDisabled"
              :messages="editNameServersDisabled ? $t ('label.updateRequired') : ''"/>
          </v-col>

          <v-expand-transition>
            <v-row
              v-if="edit.nameservers"
              class="mb-1">
              <v-col cols="12">
                <v-card>
                  <v-card-text>
                    <domain-host-data
                      ref="domainHostData"
                      v-model="hostData"/>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-expand-transition>

          <v-col
            v-if="editDNSSECVisible"
            cols="12">
            <v-switch
              v-model="dnssecEdit"
              class="ml-1"
              :label="$t ('label.editDNSSEC')"
              :disabled="editDNSSECDisabled"
              :messages="editDNSSECDisabled ? $t ('label.updateRequired') : ''"/>
          </v-col>

          <v-expand-transition>
            <v-row
              v-if="edit.dnssec"
              class="mb-1">
              <v-col cols="12">
                <v-card>
                  <v-card-text>
                    <domain-dnssec-data
                      ref="domainDnssecData"
                      v-model="dnssecData"/>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-expand-transition>

          <v-col cols="12">
            <v-switch
              v-model="edit.providerChain"
              class="ml-1"
              :label="$t ('label.editProviderChain')"/>
          </v-col>

          <v-expand-transition>
            <v-row v-show="edit.providerChain">
              <v-col cols="12">
                <v-card>
                  <v-card-text>
                    <provider-chain
                      ref="domainProviderChain"
                      v-model="chainModel"
                      external-control
                      :edit="edit.providerChain"/>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-expand-transition>
        </v-row>
      </v-slide-y-transition>
    </template>
    <template v-else-if="isLoading">
      <v-progress-linear
        indeterminate
        color="primary"/>
    </template>
    <template v-else>
      <v-alert
        v-t="'label.regNotSupported'"
        type="warning"/>
    </template>
  </div>
</template>

<script>
  import ProviderChain from '@/app/core/components/RegistryObject/ProviderChain'
  import DomainContactData from '../DomainContactData'
  import DomainDnssecData from '../DomainDnssecData'
  import DomainHostData from '../DomainHostData'

  import {mapActions, mapGetters} from 'vuex'

  export default {
    name: 'TransferUpdateData',

    components: {
      ProviderChain,
      DomainContactData,
      DomainDnssecData,
      DomainHostData
    },

    props: {
      registryId: {
        type: String,
        required: true
      },
      clientId: {
        type: Number,
        required: true
      },
      value: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        edit: {
          contacts: false,
          nameservers: false,
          dnssec: false,
          providerChain: false
        },
        isLoading: false,
        savedContactData: null,
        supportedUpdates: [],
        neededUpdates: [],
        savedHostData: [],
        savedDnsSecData: [],
        regError: false,
        registrantType: null,
        publishRegistrantData: null,
        updateObject: {
          contacts: [],
          hosts: [],
          dnssecData: [],
          providerChainSpec: '',
          providerChainType: ''
        }
      }
    },

    computed: {
      ...mapGetters ('auth', [
        'actingClientId'
      ]),

      editContactsDisabled () {
        return this.neededUpdates.includes ('contact')
      },

      editNameServersDisabled () {
        return this.neededUpdates.includes ('ns')
      },

      editDNSSECDisabled () {
        return this.neededUpdates.includes ('dnssec')
      },

      editContactsVisible () {
        return this.supportedUpdates.includes ('contact')
      },

      editNameServersVisible () {
        return this.supportedUpdates.includes ('ns')
      },

      editDNSSECVisible () {
        return this.supportedUpdates.includes ('dnssec')
      },

      chainModel: {
        get () {
          return {
            spec: this.updateObject.providerChainSpec,
            type: this.updateObject.providerChainType,
            clientId: this.clientId
          }
        },

        set (val) {
          this.updateObject.providerChainSpec = val.spec
          this.updateObject.providerChainType = val.type
        }
      },

      contactData: {
        get () {
          return {
            domain: {
              registryId: this.registryId,
              clientId: this.actingClientId,
              contacts: this.updateObject.contacts,
              wapOverride: true,
              registrantType: this.updateObject.registrantType,
              publishRegistrantData: this.updateObject.publishRegistrantData
            }
          }
        },
        set (newValue) {
          this.updateObject.contacts = newValue.domain.contacts
          this.updateObject.wapOverride = newValue.domain.wapOverride

          this.updateObject.registrantType =
            newValue.domain.registrantType

          this.updateObject.publishRegistrantData =
            newValue.domain.publishRegistrantData
        }
      },

      hostData: {
        get () {
          return this.updateObject.hosts
        },
        set (newValue) {
          this.updateObject.hosts = newValue
        }
      },

      dnssecData: {
        get () {
          return this.updateObject.dnssecData
        },
        set (newValue) {
          this.updateObject.dnssecData = newValue
        }
      },

      sendObject () {
        return {
          contacts: this.edit.contacts ? this.updateObject.contacts : null,
          hosts: this.edit.nameservers ? this.updateObject.hosts : null,
          dnssecData: this.edit.dnssec ? this.updateObject.dnssecData : null,
          providerChainSpec: this.chainModel.spec,
          providerChainType: this.chainModel.type
        }
      },

      contactEdit: {
        get () {
          return this.edit.contacts
        },

        set (e) {
          this.edit.contacts = e

          if (!e) {
            this.savedContactData = this.contactData
            this.contactData = {
              domain: {
                registryId: this.registryId,
                clientId: this.actingClientId,
                contacts: [],
                registrantType: null,
                publishRegistrantData: null,
                wapOverride: null
              }
            }
          } else {
            this.contactData = this.savedContactData
          }
        }
      },

      hostEdit: {
        get () {
          return this.edit.nameservers
        },

        set (e) {
          this.edit.nameservers = e

          if (!e) {
            this.savedHostData = this.hostData
            this.hostData = []
          } else {
            this.hostData = this.savedHostData
          }
        }
      },

      dnssecEdit: {
        get () {
          return this.edit.dnssec
        },

        set (e) {
          this.edit.dnssec = e

          if (!e) {
            this.savedDnsSecData = this.dnssecData
            this.dnssecData = []
          } else {
            this.dnssecData = this.savedDnsSecData
          }
        }
      }
    },

    watch: {
      neededUpdates () {
        this.neededUpdates.forEach ((key) => {
          this.edit[key] = true
        })
      },

      updateObject: {
        handler () {
          this.$emit ('input', this.sendObject)
        },
        deep: true
      },

      registryId () {
        this.loadRegistryConfig ()
      },

      clientId () {
        this.chainModel.clientId = this.clientId
      }
    },

    created () {
      this.contactData = {
        domain: {
          registryId: this.registryId,
          clientId: this.clientId,
          contacts: [],
          registrantType: null,
          publishRegistrantData: null,
          wapOverride: null
        }
      }

      if (this.registryId) {
        this.loadRegistryConfig ()
      }
      this.savedContactData = this.contactData
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      submit () {
        this.$emit ('send', this.sendObject)
      },

      loadRegistryConfig () {
        if (this.registryId) {
          this.isLoading = true
          this.regError = false

          this.fetchData ({
            op: 'transfer/config',
            params: {
              name: this.registryId
            },
            cb: data => {
              this.supportedUpdates = data.supportedFields
              this.neededUpdates = data.necessaryFields
              this.neededUpdates.forEach ((key) => {
                this.edit[key] = true
              })

              this.updateSwitches ()
            },
            cbError: () => {
              this.regError = true
            },
            cbFinal: () => {
              this.isLoading = false
            }
          })
        }
      },

      updateSwitches () {
        if (this.neededUpdates.includes ('contact')) {
          this.contactEdit = true
        }

        if (this.neededUpdates.includes ('ns')) {
          this.hostEdit = true
        }

        if (this.neededUpdates.includes ('dnssec')) {
          this.dnssecEdit = true
        }
      },

      reset () {
        this.edit = {
          contacts: false,
          nameservers: false,
          dnssec: false,
          providerChain: false
        }
        this.isLoading = false

        this.savedContactData = null
        this.savedHostData = []
        this.savedDnsSecData = []
        this.updateObject = {
          contacts: [],
          hosts: [],
          dnssecData: [],
          wapOverride: null,
          registrantType: null,
          publishRegistrantData: null
        }
        this.contactData = {
          domain: {
            registryId: this.registry,
            clientId: this.actingClientId,
            contacts: [],
            registrantType: null,
            publishRegistrantData: null,
            wapOverride: null
          }
        }
        this.savedContactData = this.contactData

        this.$refs.domainProviderChain.onReset ()
      },

      /**
       * Check whether the form has any errors, i.e., contains at least one
       * field with invalid data that is flagged "dirty".
       *
       * @return {Boolean}      true if so
       */
      hasErrors () {
        const contactErrors =
          this.edit.contacts && this.$refs.domainContactData &&
          this.$refs.domainContactData.hasErrors ()

        const hostErrors =
          this.edit.nameservers && this.$refs.domainHostData &&
          this.$refs.domainHostData.hasErrors ()

        const dnssecErrors =
          this.edit.dnssec && this.$refs.domainDnssecData &&
          this.$refs.domainDnssecData.hasErrors ()

        const pcErrors =
          this.edit.providerChain && this.$refs.domainProviderChain &&
          this.$refs.domainProviderChain.hasErrors ()

        return contactErrors || hostErrors || dnssecErrors || pcErrors
      },

      /**
       * Check whether the form data is valid, i.e., all fields contain valid
       * values.
       *
       * @return {Boolean}      true if so
       */
      isValid () {
        const contactValid =
          !this.edit.contacts || !this.$refs.domainContactData ||
          this.$refs.domainContactData.isValid ()

        const hostValid =
          !this.edit.nameservers || !this.$refs.domainHostData ||
          this.$refs.domainHostData.isValid ()

        const dnssecValid =
          !this.edit.dnssec || !this.$refs.domainDnssecData ||
          this.$refs.domainDnssecData.isValid ()

        const pcValid =
          !this.edit.providerChain || !this.$refs.domainProviderChain ||
          this.$refs.domainProviderChain.isValid ()

        return contactValid && hostValid && dnssecValid && pcValid
      }
    }
  }
</script>
