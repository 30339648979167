<messages>["./DomainTransfer"]</messages>

<template>
  <div>
    <registry-object-create-update
      ref="regObject"
      type="domainTransfer"
      :is-create="true"
      :registry-id="transferObject.registryId"
      :client-id="transferObject.clientId"
      :show-buttons="showStepper"
      :response="orderProcessingResponse"
      :is-loading="isLoading"
      limit-price
      @limitPriceChanged="onLimitPriceChanged"
      @registrySelected="onRegistrySelected"
      @loaded="onCreateUpdateComponentLoaded"
      @clientSelected="onClientSelected"
      @submit="onSubmit"
      @reset="onReset">
      <template #actions>
        <v-btn
          v-t="'label.startOver.label'"
          @click="onStartOver"/>
      </template>

      <template #content>
        <stepper
          v-if="showStepper"
          ref="stepper"
          v-model="transferObjectModel"/>
        <v-alert v-if="!isTransferSupportedByRegistry" type="error">
          <i18n
            :path="isVirtual(registryId) ? 'error.virtual' : 'error.transferMode'">
            <template #contact>
              <a href="mailto:partners@corehub.net">partners@corehub.net</a>
            </template>
          </i18n>
        </v-alert>
      </template>
    </registry-object-create-update>

    <confirmation-dialog
      v-model="showStartOverConfirmation"
      v-t="'label.startOver.text'"
      :headline="$t ('label.startOver.title')"
      @ok="doStartOver"/>
  </div>
</template>

<script>
  import Stepper from './Stepper'

  import ConfirmationDialog from '@/app/core/components/ConfirmationDialog'

  import registryCommands from '@/app/core/mixins/RegistryCommands'

  import RegistryObjectCreateUpdate
    from '@/app/core/components/RegistryObject/RegistryObjectCreateUpdate'

  import {mapActions, mapGetters} from 'vuex'

  export default {
    name: 'Transfer',

    components: {
      ConfirmationDialog,
      RegistryObjectCreateUpdate,
      Stepper
    },

    mixins: [registryCommands],

    props: {
      domainName: {
        type: String,
        required: true
      },
      registryId: {
        type: String,
        required: true
      }
    },

    data () {
      return {
        step: 1,
        orderProcessingResponse: null,

        isLoading: false,
        showStartOverConfirmation: false,
        transferObject: {
          clientId: 0,
          registryId: '',
          domainName: '',
          authInfo: '',
          contacts: [],
          hosts: [],
          dnssecData: [],
          providerChainSpec: '',
          providerChainType: '',
          priceLimit: {
            value: null,
            currency: null
          }
        }
      }
    },

    computed: {
      ...mapGetters ('meta', {
        transferMode: 'getTransferMode'
      }),

      isTransferSupportedByRegistry () {
        return this.transferMode !== 'push' && !this.isVirtual (this.registryId)
      },

      showStepper () {
        return !!(this.transferObject.registryId &&
          this.transferObject.clientId) && this.isTransferSupportedByRegistry
      },

      transferObjectModel: {
        get () {
          return this.transferObject
        },

        set (val) {
          this.transferObject = {
            ...this.transferObject,
            ...val
          }
        }
      }
    },

    watch: {
      registryId () {
        this.transferObject.registryId = this.registryId
      },

      domainName () {
        this.transferObject.domainName = this.domainName
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      onLimitPriceChanged (priceModel) {
        this.transferObject.priceLimit = priceModel
      },

      onRegistrySelected (registryId) {
        this.transferObject.registryId = registryId
      },

      onClientSelected (clientId) {
        if (clientId !== this.transferObject.clientId) {
          this.transferObject.clientId = clientId
        }
      },

      onSubmit () {
        // TODO, FIXME: this is a workaround for https://github.com/vuetifyjs/vuetify/issues/5203
        // (if submitted directly from combobox, then the combobox value is not updated yet, so wait...)
        // This workaround can be reverted as soon as the issue is resolved and the Vuetify library is updated
        setTimeout (() => {
          if (this.$refs.stepper.isDataValid ()) {
            this.$emit ('dataValidityChanged', true)
            this.isLoading = true
            this.fetchData ({
              op: 'transfer/request',
              params: {
                data: {
                  ...this.transferObject
                }
              },
              cb: () => {
                const domainName = this.transferObject.domainName
                this.orderProcessingResponse = null
                this.step = 1
                this.$refs.regObject.resetLimitPrice ()
                this.$refs.stepper.reset ()
                this.$emit ('success', domainName)
              },
              cbError: data => {
                this.orderProcessingResponse = data.errors

                this.$emit ('failure')
              },
              cbFinal: () => {
                this.isLoading = false
              }
            })
          } else {
            this.$emit ('dataValidityChanged', false)
            this.$refs.stepper.touchData ()
          }
        })
      },

      onCreateUpdateComponentLoaded () {
        // TODO: Load from vuex
        // this.domainObject.domain = this.domainCreateData.domain ||
        //   _cloneDeep (DefaultCreateParams.domain)

        // this.domainObject.specificFieldData =
        //   this.domainCreateData.specificFieldData

        // this.domainObject.addOnFieldData =
        //   this.domainCreateData.addOnFieldData

        // this.domainObject.editProviderChain =
        //   this.domainCreateData.editProviderChain ||
        //   DefaultCreateParams.editProviderChain
      },

      onReset () {
        this.$refs.stepper.reset ()
        this.$emit ('dataValidityChanged', true)
        this.orderProcessingResponse = null
      },

      onStartOver () {
        this.showStartOverConfirmation = true
      },

      doStartOver () {
        // TODO: this.domainObject.domain = _cloneDeep (DefaultCreateParams.domain)
        this.$emit ('startOver')
        this.showStartOverConfirmation = false
      }
    }
  }
</script>
